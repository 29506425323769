import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox } from "vue"
import _imports_0 from '@/assets/icons/arrow.svg'
import _imports_1 from '@/assets/icons/arrow_white.svg'
import _imports_2 from '@/assets/icons/white_publish_24px.png'
import _imports_3 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_4 from '@/assets/icons/white_check_2_32px.png'
import _imports_5 from '@/assets/icons/tube-spinner.svg'
import _imports_6 from '@/assets/icons/timer_20px.png'
import _imports_7 from '@/assets/icons/white_ai_assist_24dp.png'
import _imports_8 from '@/assets/icons/white_ready_32px.png'
import _imports_9 from '@/assets/icons/white_warning_32px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "body-width-reponsive-max" }
const _hoisted_3 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_4 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_5 = { class: "label-container" }
const _hoisted_6 = { class: "nav-text-header" }
const _hoisted_7 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_8 = { class: "fs-12 fw-700" }
const _hoisted_9 = { class: "fs-12 fw-700" }
const _hoisted_10 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_11 = {
  key: 1,
  src: _imports_0,
  class: "icon-16 ml-12",
  alt: "navigate_right_black_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_12 = {
  key: 2,
  src: _imports_1,
  class: "icon-16 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_13 = { class: "fs-12 fw-700" }
const _hoisted_14 = { class: "ml-24 flex-mid" }
const _hoisted_15 = {
  key: 1,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_16 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  class: "body-width",
  id: "assessment_setup"
}
const _hoisted_19 = { style: {"margin-top":"6.4rem"} }
const _hoisted_20 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_21 = { class: "step-menu-container vertical" }
const _hoisted_22 = { class: "menu-inner" }
const _hoisted_23 = { class: "step-num" }
const _hoisted_24 = { class: "step-name" }
const _hoisted_25 = { class: "menu-inner" }
const _hoisted_26 = { class: "step-num" }
const _hoisted_27 = { class: "step-name" }
const _hoisted_28 = { class: "menu-inner" }
const _hoisted_29 = { class: "step-num" }
const _hoisted_30 = { class: "step-name" }
const _hoisted_31 = { class: "" }
const _hoisted_32 = { class: "" }
const _hoisted_33 = {
  key: 0,
  class: "page-head-menu"
}
const _hoisted_34 = { class: "page-back" }
const _hoisted_35 = { class: "title" }
const _hoisted_36 = { class: "description" }
const _hoisted_37 = {
  key: 0,
  class: "pr-10 fc-primary"
}
const _hoisted_38 = {
  key: 1,
  class: "pr-10"
}
const _hoisted_39 = { class: "ml-auto flex-center" }
const _hoisted_40 = { class: "fs-12 fw-700" }
const _hoisted_41 = {
  key: 0,
  src: _imports_0,
  class: "icon-16 ml-12",
  alt: "navigate_right_black_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_42 = {
  key: 1,
  src: _imports_0,
  class: "icon-16 ml-12",
  alt: "icons_white_arrow_right_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_43 = { class: "fs-12 fw-700" }
const _hoisted_44 = { class: "fs-12 fw-700" }
const _hoisted_45 = {
  key: 0,
  src: _imports_0,
  class: "icon-16 ml-12",
  alt: "navigate_right_black_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_46 = {
  key: 1,
  src: _imports_3,
  class: "icon-16 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_47 = { class: "fs-12 fw-700" }
const _hoisted_48 = {
  key: 0,
  src: _imports_0,
  class: "icon-16 ml-12",
  alt: "navigate_right_black_24px",
  style: {"transform":"rotate(180deg)"}
}
const _hoisted_49 = {
  key: 1,
  src: _imports_3,
  class: "icon-16 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_50 = { class: "fs-12 fw-700" }
const _hoisted_51 = { class: "ml-24 flex-mid" }
const _hoisted_52 = {
  key: 5,
  class: "ml-16 btn btn-50 btn-DEDEDE"
}
const _hoisted_53 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_54 = {
  key: 1,
  class: "detail pt-40 pb-40"
}
const _hoisted_55 = {
  class: "step",
  style: {}
}
const _hoisted_56 = { class: "flex-mid active" }
const _hoisted_57 = { class: "step-container center" }
const _hoisted_58 = {
  key: 0,
  class: "flex-mid"
}
const _hoisted_59 = { key: 1 }
const _hoisted_60 = { class: "step-container center" }
const _hoisted_61 = {
  key: 0,
  class: "flex-mid"
}
const _hoisted_62 = { key: 1 }
const _hoisted_63 = { class: "step-container center" }
const _hoisted_64 = {
  key: 0,
  class: "flex-mid"
}
const _hoisted_65 = { key: 1 }
const _hoisted_66 = { class: "step-container center" }
const _hoisted_67 = { style: {"padding":"0 0 6.4rem"} }
const _hoisted_68 = { class: "step-1-container box-card" }
const _hoisted_69 = { class: "header" }
const _hoisted_70 = { class: "title" }
const _hoisted_71 = { class: "desc" }
const _hoisted_72 = { style: {"width":"42.4rem"} }
const _hoisted_73 = { class: "" }
const _hoisted_74 = { class: "input-container-02" }
const _hoisted_75 = ["placeholder"]
const _hoisted_76 = { class: "label" }
const _hoisted_77 = { class: "mt-24" }
const _hoisted_78 = {
  class: "input-container-02 radios",
  style: {"height":"12rem"}
}
const _hoisted_79 = ["id", "value", "checked"]
const _hoisted_80 = ["for"]
const _hoisted_81 = { class: "label" }
const _hoisted_82 = { key: 0 }
const _hoisted_83 = { class: "input-container-02 mt-24" }
const _hoisted_84 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_85 = { class: "label" }
const _hoisted_86 = { class: "value" }
const _hoisted_87 = {
  key: 1,
  class: "recommendation-text"
}
const _hoisted_88 = {
  key: 1,
  class: "input-container-02 mt-24"
}
const _hoisted_89 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_90 = { class: "label" }
const _hoisted_91 = { class: "value" }
const _hoisted_92 = {
  key: 1,
  class: "recommendation-text"
}
const _hoisted_93 = {
  key: 3,
  class: "input-container-02 x-closable-false mt-24",
  style: `height: auto`
}
const _hoisted_94 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_95 = {
  class: "input-container-02 radios",
  style: {"height":"12rem"}
}
const _hoisted_96 = ["id", "value", "checked"]
const _hoisted_97 = ["for", "innerHTML"]
const _hoisted_98 = { class: "label" }
const _hoisted_99 = { class: "input-container-02 dropdown mb-30" }
const _hoisted_100 = { class: "label" }
const _hoisted_101 = ["innerHTML"]
const _hoisted_102 = {
  key: 0,
  class: "your-sections-title required"
}
const _hoisted_103 = {
  key: 1,
  class: "your-sections-title"
}
const _hoisted_104 = { class: "your-sections-items" }
const _hoisted_105 = {
  key: 0,
  class: "your-sections-item no-settings empty-sections"
}
const _hoisted_106 = { class: "icon" }
const _hoisted_107 = {
  key: 0,
  class: "ai-icon-text"
}
const _hoisted_108 = {
  key: 1,
  src: _imports_5
}
const _hoisted_109 = {
  key: 2,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_110 = {
  key: 3,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_111 = { class: "icon" }
const _hoisted_112 = {
  key: 0,
  class: "ai-icon-text"
}
const _hoisted_113 = {
  key: 1,
  src: _imports_5
}
const _hoisted_114 = {
  key: 2,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_115 = {
  key: 3,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_116 = { class: "icon" }
const _hoisted_117 = {
  key: 0,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_118 = {
  key: 1,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_119 = { class: "icon" }
const _hoisted_120 = {
  key: 0,
  class: "ai-icon-text"
}
const _hoisted_121 = {
  key: 1,
  src: _imports_5
}
const _hoisted_122 = {
  key: 2,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_123 = {
  key: 3,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_124 = { class: "icon" }
const _hoisted_125 = {
  key: 0,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_126 = {
  key: 1,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_127 = { class: "icon" }
const _hoisted_128 = {
  key: 0,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_129 = {
  key: 1,
  width: "12",
  height: "9",
  viewBox: "0 0 12 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_130 = ["innerHTML"]
const _hoisted_131 = {
  key: 2,
  class: "your-sections-items-selected-total"
}
const _hoisted_132 = {
  key: 3,
  class: "section-box-container"
}
const _hoisted_133 = { class: "header" }
const _hoisted_134 = { class: "time flex-center" }
const _hoisted_135 = { class: "model-type" }
const _hoisted_136 = { class: "title" }
const _hoisted_137 = { class: "description" }
const _hoisted_138 = { key: 0 }
const _hoisted_139 = { class: "flex footer" }
const _hoisted_140 = ["onClick"]
const _hoisted_141 = { class: "true" }
const _hoisted_142 = { class: "flex-center" }
const _hoisted_143 = { class: "false" }
const _hoisted_144 = ["onClick"]
const _hoisted_145 = ["onClick"]
const _hoisted_146 = ["onClick"]
const _hoisted_147 = { key: 4 }
const _hoisted_148 = {
  key: 0,
  class: "box-card p-24 mt-24"
}
const _hoisted_149 = { class: "fs-16 fw-700" }
const _hoisted_150 = { class: "fs-14 mt-12" }
const _hoisted_151 = { class: "mt-32 flex-center" }
const _hoisted_152 = { class: "" }
const _hoisted_153 = {
  key: 1,
  ref: "top_of_setup_card"
}
const _hoisted_154 = {
  key: 2,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_155 = { class: "title" }
const _hoisted_156 = { class: "description" }
const _hoisted_157 = { class: "flex-center" }
const _hoisted_158 = {
  key: 0,
  class: "btn btn-50 x-btn-primary max-m-mt-24 checkbox-set-up",
  style: {"background-color":"#dedede","cursor":"wait"}
}
const _hoisted_159 = { class: "label mr-auto" }
const _hoisted_160 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_161 = { class: "label" }
const _hoisted_162 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_163 = {
  key: 0,
  class: "label"
}
const _hoisted_164 = {
  key: 1,
  class: "label"
}
const _hoisted_165 = {
  key: 3,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_166 = { class: "title" }
const _hoisted_167 = { class: "description" }
const _hoisted_168 = { class: "flex-center" }
const _hoisted_169 = {
  key: 0,
  class: "btn btn-50 x-btn-primary max-m-mt-24 checkbox-set-up",
  style: {"background-color":"#dedede","cursor":"wait"}
}
const _hoisted_170 = { class: "label mr-auto" }
const _hoisted_171 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_172 = { class: "label" }
const _hoisted_173 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_174 = {
  key: 0,
  class: "label"
}
const _hoisted_175 = {
  key: 1,
  class: "label"
}
const _hoisted_176 = {
  key: 4,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_177 = { class: "title" }
const _hoisted_178 = { class: "description" }
const _hoisted_179 = { class: "flex-center" }
const _hoisted_180 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_181 = { class: "label" }
const _hoisted_182 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_183 = { class: "label" }
const _hoisted_184 = {
  key: 5,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_185 = { class: "title" }
const _hoisted_186 = { class: "description" }
const _hoisted_187 = { class: "flex-center" }
const _hoisted_188 = { class: "checkbox-set-up flex-center checked" }
const _hoisted_189 = { class: "flex-center" }
const _hoisted_190 = { class: "label" }
const _hoisted_191 = {
  key: 6,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_192 = { class: "title" }
const _hoisted_193 = { class: "description" }
const _hoisted_194 = { class: "flex-center" }
const _hoisted_195 = { class: "checkbox-set-up flex-center checked" }
const _hoisted_196 = { class: "flex-center" }
const _hoisted_197 = { class: "label" }
const _hoisted_198 = {
  key: 7,
  class: "box-step-three box-card p-24 mt-24"
}
const _hoisted_199 = { class: "title" }
const _hoisted_200 = { class: "description" }
const _hoisted_201 = { class: "flex-center" }
const _hoisted_202 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_203 = { class: "label" }
const _hoisted_204 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_205 = { class: "label" }
const _hoisted_206 = {
  key: 5,
  class: "box-step-four"
}
const _hoisted_207 = { class: "box-card section-box" }
const _hoisted_208 = { class: "header" }
const _hoisted_209 = { class: "title" }
const _hoisted_210 = { class: "desc" }
const _hoisted_211 = { class: "container" }
const _hoisted_212 = { class: "row" }
const _hoisted_213 = { class: "row" }
const _hoisted_214 = { class: "row" }
const _hoisted_215 = { class: "row" }
const _hoisted_216 = {
  key: 0,
  class: "box-card section-box"
}
const _hoisted_217 = { class: "header row fs-14 fw-700" }
const _hoisted_218 = { class: "flex-center" }
const _hoisted_219 = {
  class: "flex-mid center",
  style: {"justify-content":"end"}
}
const _hoisted_220 = {
  key: 0,
  class: "row time fs-16 fw-700"
}
const _hoisted_221 = { class: "flex-center" }
const _hoisted_222 = {
  class: "flex-mid",
  style: {"justify-content":"end"}
}
const _hoisted_223 = {
  key: 1,
  class: "row time fs-16 fw-700"
}
const _hoisted_224 = { class: "flex-center" }
const _hoisted_225 = {
  class: "flex-mid",
  style: {"justify-content":"end"}
}
const _hoisted_226 = {
  key: 2,
  class: "row time fs-16 fw-700"
}
const _hoisted_227 = { class: "flex-center" }
const _hoisted_228 = {
  class: "flex-mid",
  style: {"justify-content":"end"}
}
const _hoisted_229 = {
  key: 3,
  class: "row time fs-16 fw-700"
}
const _hoisted_230 = { class: "flex-center" }
const _hoisted_231 = {
  class: "flex-mid",
  style: {"justify-content":"end"}
}
const _hoisted_232 = {
  key: 4,
  class: "row time fs-16 fw-700"
}
const _hoisted_233 = { class: "flex-center" }
const _hoisted_234 = {
  class: "flex-mid",
  style: {"justify-content":"end"}
}
const _hoisted_235 = { class: "box-card section-box advanced-options p-24" }
const _hoisted_236 = { class: "title" }
const _hoisted_237 = { class: "mt-12 fs-14 fc-696C80" }
const _hoisted_238 = { class: "container" }
const _hoisted_239 = { class: "row" }
const _hoisted_240 = { class: "title" }
const _hoisted_241 = {
  class: "mt-16",
  style: {"display":"none"}
}
const _hoisted_242 = { class: "menu" }
const _hoisted_243 = { class: "row" }
const _hoisted_244 = { class: "title" }
const _hoisted_245 = {
  class: "checkbox-primary mt-24",
  style: {"display":"none"}
}
const _hoisted_246 = { class: "checkbox-style" }
const _hoisted_247 = { class: "menu" }
const _hoisted_248 = {
  class: "row",
  style: {"display":"none"}
}
const _hoisted_249 = { class: "title" }
const _hoisted_250 = {
  class: "checkbox-primary mt-24",
  style: {"display":"none"}
}
const _hoisted_251 = { class: "checkbox-style" }
const _hoisted_252 = { class: "menu" }
const _hoisted_253 = {
  class: "row",
  style: {"border":"0"}
}
const _hoisted_254 = { class: "title" }
const _hoisted_255 = {
  class: "checkbox-primary mt-24",
  style: {"display":"none"}
}
const _hoisted_256 = { class: "menu" }
const _hoisted_257 = {
  key: 0,
  class: "row-edit"
}
const _hoisted_258 = { class: "input-container-02" }
const _hoisted_259 = { class: "button-container" }
const _hoisted_260 = { class: "remove-header remove-padding-modal" }
const _hoisted_261 = { class: "fc-1B1C1E word-break-normal" }
const _hoisted_262 = { class: "el-dialog-title" }
const _hoisted_263 = { class: "el-dialog-message" }
const _hoisted_264 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_265 = { class: "remove-header remove-padding-modal" }
const _hoisted_266 = { class: "fc-1B1C1E word-break-normal" }
const _hoisted_267 = { class: "el-dialog-title" }
const _hoisted_268 = { class: "el-dialog-message" }
const _hoisted_269 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_270 = { class: "remove-header remove-padding-modal" }
const _hoisted_271 = { class: "fc-1B1C1E word-break-normal" }
const _hoisted_272 = { class: "el-dialog-title" }
const _hoisted_273 = { class: "el-dialog-message" }
const _hoisted_274 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_275 = { class: "remove-header remove-padding-modal" }
const _hoisted_276 = { class: "fc-1B1C1E word-break-normal" }
const _hoisted_277 = { class: "el-dialog-title" }
const _hoisted_278 = { class: "el-dialog-message" }
const _hoisted_279 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_280 = { class: "remove-header remove-padding-modal" }
const _hoisted_281 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_282 = { class: "column-left" }
const _hoisted_283 = { class: "note" }
const _hoisted_284 = { class: "title" }
const _hoisted_285 = { class: "message-body" }
const _hoisted_286 = { class: "message-group" }
const _hoisted_287 = { class: "subtitle" }
const _hoisted_288 = { class: "message" }
const _hoisted_289 = { class: "message-group" }
const _hoisted_290 = { class: "subtitle" }
const _hoisted_291 = ["innerHTML"]
const _hoisted_292 = { class: "column-right" }
const _hoisted_293 = { class: "message-body" }
const _hoisted_294 = { class: "message-group" }
const _hoisted_295 = { class: "subtitle" }
const _hoisted_296 = { class: "message" }
const _hoisted_297 = { class: "message-group" }
const _hoisted_298 = { class: "subtitle" }
const _hoisted_299 = { class: "message" }
const _hoisted_300 = { class: "remove-header remove-padding-modal" }
const _hoisted_301 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_302 = { class: "column-left" }
const _hoisted_303 = { class: "note" }
const _hoisted_304 = { class: "title" }
const _hoisted_305 = { class: "message-body" }
const _hoisted_306 = { class: "message-group" }
const _hoisted_307 = { class: "subtitle" }
const _hoisted_308 = { class: "message" }
const _hoisted_309 = { class: "message-group" }
const _hoisted_310 = { class: "subtitle" }
const _hoisted_311 = ["innerHTML"]
const _hoisted_312 = { class: "column-right" }
const _hoisted_313 = { class: "message-body" }
const _hoisted_314 = { class: "message-group" }
const _hoisted_315 = { class: "subtitle" }
const _hoisted_316 = { class: "message" }
const _hoisted_317 = { class: "message-group" }
const _hoisted_318 = { class: "subtitle" }
const _hoisted_319 = { class: "message" }
const _hoisted_320 = { class: "remove-header remove-padding-modal" }
const _hoisted_321 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_322 = { class: "column-left" }
const _hoisted_323 = { class: "note" }
const _hoisted_324 = { class: "title" }
const _hoisted_325 = { class: "message-body" }
const _hoisted_326 = { class: "message-group" }
const _hoisted_327 = { class: "subtitle" }
const _hoisted_328 = { class: "message" }
const _hoisted_329 = { class: "message-group" }
const _hoisted_330 = { class: "subtitle" }
const _hoisted_331 = ["innerHTML"]
const _hoisted_332 = { class: "column-right" }
const _hoisted_333 = { class: "message-body" }
const _hoisted_334 = { class: "message-group" }
const _hoisted_335 = { class: "subtitle" }
const _hoisted_336 = { class: "message" }
const _hoisted_337 = { class: "message-group" }
const _hoisted_338 = { class: "subtitle" }
const _hoisted_339 = { class: "message" }
const _hoisted_340 = { class: "remove-header remove-padding-modal" }
const _hoisted_341 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_342 = { class: "column-left" }
const _hoisted_343 = { class: "note" }
const _hoisted_344 = { class: "title" }
const _hoisted_345 = { class: "message-body" }
const _hoisted_346 = { class: "message-group" }
const _hoisted_347 = { class: "subtitle" }
const _hoisted_348 = { class: "message" }
const _hoisted_349 = { class: "message-group" }
const _hoisted_350 = { class: "subtitle" }
const _hoisted_351 = ["innerHTML"]
const _hoisted_352 = { class: "column-right" }
const _hoisted_353 = { class: "message-body" }
const _hoisted_354 = { class: "message-group" }
const _hoisted_355 = { class: "subtitle" }
const _hoisted_356 = { class: "message" }
const _hoisted_357 = { class: "message-group" }
const _hoisted_358 = { class: "subtitle" }
const _hoisted_359 = { class: "message" }
const _hoisted_360 = { class: "remove-header remove-padding-modal" }
const _hoisted_361 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_362 = { class: "column-left" }
const _hoisted_363 = { class: "note" }
const _hoisted_364 = { class: "title" }
const _hoisted_365 = { class: "message-body" }
const _hoisted_366 = { class: "message-group" }
const _hoisted_367 = { class: "subtitle" }
const _hoisted_368 = { class: "message" }
const _hoisted_369 = { class: "message-group" }
const _hoisted_370 = { class: "subtitle" }
const _hoisted_371 = ["innerHTML"]
const _hoisted_372 = { class: "column-right" }
const _hoisted_373 = { class: "message-body" }
const _hoisted_374 = { class: "message-group" }
const _hoisted_375 = { class: "subtitle" }
const _hoisted_376 = { class: "message" }
const _hoisted_377 = { class: "message-group" }
const _hoisted_378 = { class: "subtitle" }
const _hoisted_379 = { class: "message" }
const _hoisted_380 = { class: "remove-header remove-padding-modal" }
const _hoisted_381 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_382 = { class: "column-left" }
const _hoisted_383 = { class: "note" }
const _hoisted_384 = { class: "title" }
const _hoisted_385 = { class: "message-body" }
const _hoisted_386 = { class: "message-group" }
const _hoisted_387 = { class: "subtitle" }
const _hoisted_388 = { class: "message" }
const _hoisted_389 = { class: "message-group" }
const _hoisted_390 = { class: "subtitle" }
const _hoisted_391 = ["innerHTML"]
const _hoisted_392 = { class: "column-right" }
const _hoisted_393 = { class: "message-body" }
const _hoisted_394 = { class: "message-group" }
const _hoisted_395 = { class: "subtitle" }
const _hoisted_396 = { class: "message" }
const _hoisted_397 = {
  key: 0,
  class: "message-group"
}
const _hoisted_398 = { class: "subtitle" }
const _hoisted_399 = { class: "message" }
const _hoisted_400 = { class: "remove-header remove-padding-modal" }
const _hoisted_401 = { class: "fc-1B1C1E word-break-normal row" }
const _hoisted_402 = { class: "column-left" }
const _hoisted_403 = { class: "note" }
const _hoisted_404 = { class: "title" }
const _hoisted_405 = { class: "message-body" }
const _hoisted_406 = { class: "message-group" }
const _hoisted_407 = { class: "subtitle" }
const _hoisted_408 = { class: "message" }
const _hoisted_409 = { class: "message-group" }
const _hoisted_410 = { class: "subtitle" }
const _hoisted_411 = ["innerHTML"]
const _hoisted_412 = { class: "column-right" }
const _hoisted_413 = { class: "message-body" }
const _hoisted_414 = { class: "message-group" }
const _hoisted_415 = { class: "subtitle" }
const _hoisted_416 = { class: "message" }
const _hoisted_417 = { class: "message-group" }
const _hoisted_418 = { class: "subtitle" }
const _hoisted_419 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
              class: "btn-left"
            }, _cache[79] || (_cache[79] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "icons_navigate_right_black_24px",
                class: "icon-16 animetion"
              }, null, -1)
            ])),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(
                  _ctx.isNew
                    ? "create.Create_new_assessment"
                    : "create.Edit_assessment"
                )), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.isFirstStep)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                  class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                }, [
                  _cache[80] || (_cache[80] = _createElementVNode("img", {
                    src: _imports_0,
                    class: "icon-16 mr-12",
                    alt: "navigate_right_black_24px"
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("create.Previous")), 1)
                ], 2))
              : _createCommentVNode("", true),
            (!_ctx.isLastStep)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                  class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", `${_ctx.canNextStep ? 'btn-primary' : 'disable-true'} ${
              _ctx.aiLoading && (_ctx.step == 1 || _ctx.step == 2) ? 'ui-loading' : ''
            }`])
                }, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("create.Next")), 1),
                  (_ctx.aiLoading && (_ctx.step == 1 || _ctx.step == 2))
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[81] || (_cache[81] = [
                        _createElementVNode("path", {
                          fill: "#aaa",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true),
                  (
                !(_ctx.aiLoading && (_ctx.step == 1 || _ctx.step == 2)) &&
                _ctx.btnNexstepOne &&
                _ctx.isLightColor
              )
                    ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                    : _createCommentVNode("", true),
                  (
                !(_ctx.aiLoading && (_ctx.step == 1 || _ctx.step == 2)) &&
                !(_ctx.btnNexstepOne && _ctx.isLightColor)
              )
                    ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.isLastStep)
              ? (_openBlock(), _createBlock(_component_el_popover, {
                  key: 2,
                  visible: _ctx.isShowedGuidedTourCreate,
                  placement: "bottom",
                  width: 220,
                  "popper-class": "mc-popover"
                }, {
                  reference: _withCtx(() => [
                    (_ctx.publishSubmitStatus)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                          class: "ml-16 btn02 btn-40 btn-primary"
                        }, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t(_ctx.isNew ? "create.Publish" : "btn.Save")), 1),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("img", {
                              src: _imports_2,
                              class: "icon-20",
                              style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                              alt: "icons_white_publish_24px"
                            }, null, 4)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(_ctx.isNew ? "create.Publish" : "btn.Save")), 1),
                          _cache[82] || (_cache[82] = _createElementVNode("svg", {
                            width: "3rem",
                            height: "3rem",
                            version: "1.1",
                            id: "L9",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            x: "0px",
                            y: "0px",
                            viewBox: "0 0 100 100",
                            "enable-background": "new 0 0 0 0",
                            "xml:space": "preserve"
                          }, [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ])
                          ], -1))
                        ]))
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "popover-content",
                      innerHTML: _ctx.$t('guided_tour.create.step_3')
                    }, null, 8, _hoisted_17)
                  ]),
                  _: 1
                }, 8, ["visible"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              class: _normalizeClass(`menu ${_ctx.tabNextStepCls(1)}`),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.gotoStep(1)))
            }, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("create.STEP_1")), 1),
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("create.Name_Assessment")), 1)
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(`menu ${_ctx.tabNextStepCls(2)}`),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.gotoStep(2)))
            }, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("create.STEP_2")), 1),
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("create.Add_Section")), 1)
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(`menu ${_ctx.tabNextStepCls(3)}`),
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.noop && _ctx.noop(...args)))
            }, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("create.STEP_3")), 1),
                _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("create.Review_and_Publish")), 1)
              ])
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              (_ctx.debug)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", {
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.backStep && _ctx.backStep(...args))),
                        class: "btn-back btn02 btn-48"
                      }, _cache[83] || (_cache[83] = [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "icons_navigate_left_32px",
                          class: "icon-16"
                        }, null, -1)
                      ])),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t(
                        _ctx.isNew
                          ? "create.Create_new_assessment"
                          : "create.Edit_assessment"
                      )), 1),
                        _createElementVNode("div", _hoisted_36, [
                          (_ctx.form.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.form.name), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.$t("create.Untitled_assessment")), 1))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      (_ctx.step == 1 && !_ctx.isStartedTest)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.oneNextstep && _ctx.oneNextstep(...args))),
                            class: _normalizeClass(["ml-16 btn02 btn-48", `${_ctx.btnNexstepOne ? 'btn-primary' : 'disable-true'}`])
                          }, [
                            _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("create.Next_Step")), 1),
                            (_ctx.btnNexstepOne && _ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_41))
                              : (_openBlock(), _createElementBlock("img", _hoisted_42))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.step == 1 && _ctx.isStartedTest)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.oneNextstepStartedTest && _ctx.oneNextstepStartedTest(...args))),
                            class: _normalizeClass(["ml-16 btn02 btn-48", `${_ctx.btnNexstepOne ? 'btn-primary' : 'disable-true'}`])
                          }, [
                            _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.$t(_ctx.isNew ? "create.Publish" : "btn.Save")), 1),
                            _cache[84] || (_cache[84] = _createElementVNode("div", { class: "ml-24 flex-mid" }, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                class: "icon-20",
                                alt: "icons_white_publish_24px"
                              })
                            ], -1))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.step == 2)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.twoNextstep && _ctx.twoNextstep(...args))),
                            class: _normalizeClass(["ml-16 btn02 btn-48", `${_ctx.btnNexstepTwo ? 'btn-primary' : 'disable-true'}`])
                          }, [
                            _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("create.Next_Step")), 1),
                            (_ctx.btnNexstepTwo && _ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_45))
                              : (_openBlock(), _createElementBlock("img", _hoisted_46))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.step == 3)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.threeNextstep && _ctx.threeNextstep(...args))),
                            class: _normalizeClass(["ml-16 btn02 btn-48", `${_ctx.btnNexstepThree ? 'btn-primary' : 'disable-true'}`])
                          }, [
                            _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t("create.Next_Step")), 1),
                            (_ctx.btnNexstepThree && _ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_48))
                              : (_openBlock(), _createElementBlock("img", _hoisted_49))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.step == 4 && _ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                            class: "ml-16 btn02 btn-48 btn-primary"
                          }, [
                            _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t(_ctx.isNew ? "create.Publish" : "btn.Save")), 1),
                            _createElementVNode("div", _hoisted_51, [
                              _createElementVNode("img", {
                                src: _imports_2,
                                class: "icon-20",
                                style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                                alt: "icons_white_publish_24px"
                              }, null, 4)
                            ])
                          ]))
                        : (_ctx.step == 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t(_ctx.isNew ? "create.Publish" : "btn.Save")), 1),
                              _cache[85] || (_cache[85] = _createElementVNode("svg", {
                                version: "1.1",
                                id: "L9",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                x: "0px",
                                y: "0px",
                                viewBox: "0 0 100 100",
                                "enable-background": "new 0 0 0 0",
                                "xml:space": "preserve"
                              }, [
                                _createElementVNode("path", {
                                  fill: "#aaa",
                                  d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                }, [
                                  _createElementVNode("animateTransform", {
                                    attributeName: "transform",
                                    attributeType: "XML",
                                    type: "rotate",
                                    dur: "1s",
                                    from: "0 50 50",
                                    to: "360 50 50",
                                    repeatCount: "indefinite"
                                  })
                                ])
                              ], -1))
                            ]))
                          : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.debug)
                ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("div", {
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.gotoStep(1))),
                            class: _normalizeClass(["step-box mx-auto", `${_ctx.btnNextStepCls(1)}`])
                          }, [
                            (_ctx.step > 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_58, _cache[86] || (_cache[86] = [
                                  _createElementVNode("img", {
                                    src: _imports_4,
                                    class: "icon-24",
                                    alt: "icons_white_check_2_32px"
                                  }, null, -1)
                                ])))
                              : (_openBlock(), _createElementBlock("div", _hoisted_59, "1"))
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(`mt-14 label ${_ctx.step == 1 ? 'show' : ''}`)
                          }, _toDisplayString(_ctx.$t("create.Name_Assessment")), 3)
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(`flex-mid relative ${_ctx.step > 1 ? 'active' : ''}`)
                      }, [
                        _cache[88] || (_cache[88] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("div", _hoisted_60, [
                          _createElementVNode("div", {
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.gotoStep(2))),
                            class: _normalizeClass(["step-box mx-auto", `${_ctx.btnNextStepCls(2)}`])
                          }, [
                            (_ctx.step > 2)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_61, _cache[87] || (_cache[87] = [
                                  _createElementVNode("img", {
                                    src: _imports_4,
                                    class: "icon-24",
                                    alt: "icons_white_check_2_32px"
                                  }, null, -1)
                                ])))
                              : (_openBlock(), _createElementBlock("div", _hoisted_62, "2"))
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(`mt-14 label ${_ctx.step == 2 ? 'show' : ''}`)
                          }, _toDisplayString(_ctx.$t("create.Select_Sections")), 3)
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(`flex-mid relative ${_ctx.step > 2 ? 'active' : ''}`)
                      }, [
                        _cache[90] || (_cache[90] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("div", _hoisted_63, [
                          _createElementVNode("div", {
                            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.gotoStep(3))),
                            class: _normalizeClass(["step-box mx-auto", `${_ctx.btnNextStepCls(3)}`])
                          }, [
                            (_ctx.step > 3)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_64, _cache[89] || (_cache[89] = [
                                  _createElementVNode("img", {
                                    src: _imports_4,
                                    class: "icon-24",
                                    alt: "icons_white_check_2_32px"
                                  }, null, -1)
                                ])))
                              : (_openBlock(), _createElementBlock("div", _hoisted_65, "3"))
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(`mt-14 label ${_ctx.step == 3 ? 'show' : ''}`)
                          }, _toDisplayString(_ctx.$t("create.Your_Culture_Model")), 3)
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(`flex-mid relative ${_ctx.step > 3 ? 'active' : ''}`)
                      }, [
                        _cache[91] || (_cache[91] = _createElementVNode("div", { class: "line" }, null, -1)),
                        _createElementVNode("div", _hoisted_66, [
                          _createElementVNode("div", {
                            onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.gotoStep(4))),
                            class: _normalizeClass(["step-box mx-auto", `${_ctx.btnNextStepCls(4)}`])
                          }, " 4 ", 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(`mt-14 label ${_ctx.step == 4 ? 'show' : ''}`)
                          }, _toDisplayString(_ctx.$t("create.Review_and_Publish")), 3)
                        ])
                      ], 2)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_67, [
                (_ctx.step == 1)
                  ? (_openBlock(), _createBlock(_component_el_popover, {
                      key: 0,
                      visible: _ctx.isShowedGuidedTourCreate,
                      placement: "left",
                      width: 220,
                      "popper-class": "mc-popover"
                    }, {
                      reference: _withCtx(() => [
                        _createElementVNode("div", _hoisted_68, [
                          _createElementVNode("div", _hoisted_69, [
                            _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.$t("create.Name_Assessment")), 1),
                            _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("create.Lets_set_up_your_assessment")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_72, [
                            _createElementVNode("div", _hoisted_73, [
                              _createElementVNode("div", _hoisted_74, [
                                _withDirectives(_createElementVNode("input", {
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.name) = $event)),
                                  onChange: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.onChangeFormName && _ctx.onChangeFormName(...args))),
                                  class: _normalizeClass(`input ${_ctx.validateStyle(_ctx.validateForm.name)}`),
                                  placeholder: 
                              _ctx.$t('create.Assessment_Name_Placeholder')
                            ,
                                  type: "text"
                                }, null, 42, _hoisted_75), [
                                  [_vModelText, _ctx.form.name]
                                ]),
                                _createElementVNode("label", _hoisted_76, _toDisplayString(_ctx.$t("create.Assessment_Name")) + "* ", 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_77, [
                              _createElementVNode("div", _hoisted_78, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intendedforOptions, (item, key) => {
                                  return (_openBlock(), _createElementBlock("div", { key: key }, [
                                    _createElementVNode("input", {
                                      type: "radio",
                                      id: `intendFor_${key}`,
                                      name: "intendedfor",
                                      value: _ctx.$t('optional.' + item),
                                      checked: _ctx.isIntendedforChecked(item),
                                      onChange: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.onIntendForChange && _ctx.onIntendForChange(...args)))
                                    }, null, 40, _hoisted_79),
                                    _createElementVNode("label", {
                                      for: `intendFor_${key}`
                                    }, _toDisplayString(_ctx.$t("optional." + item)), 9, _hoisted_80)
                                  ]))
                                }), 128)),
                                _createElementVNode("label", _hoisted_81, _toDisplayString(_ctx.$t("Purpose")), 1)
                              ])
                            ]),
                            (
                          _ctx.isEnabledInputForAi &&
                          _ctx.valuesAndTraits &&
                          _ctx.valuesAndTraits.scenarios &&
                          _ctx.valuesAndTraits.scenarios.definingYourValues &&
                          _ctx.valuesAndTraits.scenarios.definingYourValues.questions
                        )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                  _createElementVNode("div", _hoisted_83, [
                                    _createVNode(_component_el_select, {
                                      modelValue: _ctx.form.roleSelected,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.roleSelected) = $event)),
                                      "x-multiple": "",
                                      filterable: "",
                                      "allow-create": "",
                                      "default-first-option": "",
                                      "reserve-keyword": false,
                                      placeholder: "Eg. Software Engineer",
                                      class: _normalizeClass(`input-44-0000000d select no-icon ${
                              _ctx.validateForm.roleSelected ? 'error' : ''
                            }`)
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleOptions, (item) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: item,
                                            label: item,
                                            value: item
                                          }, null, 8, ["label", "value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue", "class"]),
                                    _createElementVNode("label", _hoisted_84, _toDisplayString(_ctx.$t("toolbox.Role_focus")), 1)
                                  ]),
                                  (_ctx.orgTypeEditable)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "editable-input mt-24",
                                        onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.orgTypeEditable = false))
                                      }, [
                                        _createElementVNode("label", _hoisted_85, _toDisplayString(_ctx.$t("toolbox.Organization_Type")), 1),
                                        _createElementVNode("div", _hoisted_86, [
                                          (_ctx.form.typeOfOrgSelected && _ctx.form.typeOfOrgSelected.length > 0)
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                _createTextVNode(_toDisplayString(_ctx.form.typeOfOrgSelected), 1)
                                              ], 64))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_87, [
                                                _createElementVNode("i", null, _toDisplayString(_ctx.$t("create.Please_specify_organization_type_to_tailor_your_assessment_appropriately")), 1)
                                              ])),
                                          _cache[92] || (_cache[92] = _createElementVNode("svg", {
                                            width: "12",
                                            height: "13",
                                            viewBox: "0 0 12 13",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }, [
                                            _createElementVNode("path", {
                                              d: "M8.48527 5.38322L7.54247 4.4404L1.33333 10.6496V11.5924H2.27614L8.48527 5.38322ZM9.42807 4.4404L10.3709 3.4976L9.42807 2.55479L8.48527 3.4976L9.42807 4.4404ZM2.82843 12.9257H0V10.0973L8.95667 1.14058C9.21707 0.880224 9.63913 0.880224 9.89947 1.14058L11.7851 3.02619C12.0455 3.28654 12.0455 3.70865 11.7851 3.969L2.82843 12.9257Z",
                                              fill: "black"
                                            })
                                          ], -1))
                                        ])
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_88, [
                                        _createVNode(_component_el_select, {
                                          modelValue: _ctx.form.typeOfOrgSelected,
                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.typeOfOrgSelected) = $event)),
                                          "x-multiple": "",
                                          filterable: "",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          "reserve-keyword": false,
                                          placeholder: "Eg. Technology Startup",
                                          class: _normalizeClass(`input-44-0000000d select no-icon ${
                              _ctx.validateForm.typeOfOrgSelected ? 'error' : ''
                            }`)
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOfOrgOptions, (item) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: item,
                                                label: item,
                                                value: item
                                              }, null, 8, ["label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue", "class"]),
                                        _createElementVNode("label", _hoisted_89, _toDisplayString(_ctx.$t("toolbox.Organization_Type")), 1)
                                      ])),
                                  (_ctx.coreValuesEditable)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 2,
                                        class: "editable-input mt-24",
                                        onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.coreValuesEditable = false))
                                      }, [
                                        _createElementVNode("label", _hoisted_90, _toDisplayString(_ctx.$t("toolbox.Your_Company_Values")), 1),
                                        _createElementVNode("div", _hoisted_91, [
                                          (
                                _ctx.form.coreValuesSelected &&
                                _ctx.form.coreValuesSelected.length > 0
                              )
                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                _createTextVNode(_toDisplayString(_ctx.join(_ctx.form.coreValuesSelected, ", ")), 1)
                                              ], 64))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_92, _toDisplayString(_ctx.$t(
                                    "create.We_recommend_selecting_core_values_to_help_configure_your_assessment"
                                  )), 1)),
                                          _cache[93] || (_cache[93] = _createElementVNode("svg", {
                                            width: "12",
                                            height: "13",
                                            viewBox: "0 0 12 13",
                                            fill: "none",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }, [
                                            _createElementVNode("path", {
                                              d: "M8.48527 5.38322L7.54247 4.4404L1.33333 10.6496V11.5924H2.27614L8.48527 5.38322ZM9.42807 4.4404L10.3709 3.4976L9.42807 2.55479L8.48527 3.4976L9.42807 4.4404ZM2.82843 12.9257H0V10.0973L8.95667 1.14058C9.21707 0.880224 9.63913 0.880224 9.89947 1.14058L11.7851 3.02619C12.0455 3.28654 12.0455 3.70865 11.7851 3.969L2.82843 12.9257Z",
                                              fill: "black"
                                            })
                                          ], -1))
                                        ])
                                      ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_93, [
                                        _createVNode(_component_el_select, {
                                          modelValue: _ctx.form.coreValuesSelected,
                                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.coreValuesSelected) = $event)),
                                          multiple: "",
                                          filterable: "",
                                          "multiple-limit": "8",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          "reserve-keyword": false,
                                          placeholder: "Add up to 8 values",
                                          class: _normalizeClass(`input-44-0000000d select no-icon ${
                              _ctx.validateForm.coreValuesSelected ? 'error' : ''
                            }`),
                                          style: `height: auto`
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios
                                .definingYourValues.questions, (item) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: item.id,
                                                label: _ctx.lang(item, 'title'),
                                                value: _ctx.lang(item, 'title')
                                              }, null, 8, ["label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue", "class"]),
                                        _createElementVNode("label", _hoisted_94, _toDisplayString(_ctx.$t("toolbox.Your_Company_Values")), 1)
                                      ]))
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", {
                              class: "mt-24",
                              style: _normalizeStyle({ display: _ctx.isUserMode ? 'none' : '' })
                            }, [
                              _createElementVNode("div", _hoisted_95, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessmentPermissions, (item, key) => {
                                  return (_openBlock(), _createElementBlock("div", { key: key }, [
                                    _createElementVNode("input", {
                                      type: "radio",
                                      id: `assessmentPermissions_${key}`,
                                      name: "assessmentPermission",
                                      value: item,
                                      checked: _ctx.isAssessemntPermissionChecked(item),
                                      onChange: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.onAssessmentPermissionChange && _ctx.onAssessmentPermissionChange(...args)))
                                    }, null, 40, _hoisted_96),
                                    _createElementVNode("label", {
                                      for: `assessmentPermissions_${key}`,
                                      innerHTML: _ctx.$t('create.TeamAccess.' + item)
                                    }, null, 8, _hoisted_97)
                                  ]))
                                }), 128)),
                                _createElementVNode("label", _hoisted_98, _toDisplayString(_ctx.$t("create.Assessment_Permissions")), 1)
                              ])
                            ], 4),
                            _createElementVNode("div", _hoisted_99, [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.form.langRequired,
                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.form.langRequired) = $event)),
                                placeholder: 
                            _ctx.$t('create.Languages_required_to_support')
                          ,
                                class: _normalizeClass(`input-44-0000000d select no-icon mt-24 tag-primary-color ${_ctx.validateStyle(
                            _ctx.validateForm.langRequired
                          )}`),
                                multiple: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langRequired, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "placeholder", "class"]),
                              _createElementVNode("label", _hoisted_100, _toDisplayString(_ctx.$t("Language")), 1)
                            ])
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "popover-content",
                          innerHTML: _ctx.$t('guided_tour.create.step_1')
                        }, null, 8, _hoisted_101)
                      ]),
                      _: 1
                    }, 8, ["visible"]))
                  : _createCommentVNode("", true),
                (_ctx.step == 2)
                  ? (_openBlock(), _createBlock(_component_el_popover, {
                      key: 1,
                      visible: _ctx.isShowedGuidedTourCreate,
                      placement: "right",
                      width: 220,
                      "popper-class": "mc-popover"
                    }, {
                      reference: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "your-sections",
                          style: _normalizeStyle(`${_ctx.yourSectionsStyles()}`)
                        }, [
                          (_ctx.yourSectionsIsRequired)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_102, _toDisplayString(_ctx.$t("create.SET_UP_REQUIRED")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_103, _toDisplayString(_ctx.$t("create.Your_Sections")), 1)),
                          _createElementVNode("div", _hoisted_104, [
                            (_ctx.isEmptySections)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Add_a_Section")), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.values_and_traits)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(["your-sections-item", `${_ctx.hasValuesAndTraits ? '' : 'required'}  ${
                          _ctx.canEdit ? '' : 'disable-true'
                        } ${
                          _ctx.isLoadingByAi('values_and_traits') ? 'ai-icon' : ''
                        }`]),
                                  onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.canEdit && _ctx.setUpValuesAndTraits(_ctx.formId)))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Values_Alignment")), 1),
                                  _createElementVNode("div", _hoisted_106, [
                                    (_ctx.isLoadingByAi('values_and_traits'))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_107, "AI"))
                                      : _createCommentVNode("", true),
                                    (_ctx.isLoadingByAi('values_and_traits'))
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_108))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('values_and_traits') &&
                              !_ctx.hasValuesAndTraits
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_109, _cache[94] || (_cache[94] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('values_and_traits') &&
                              _ctx.hasValuesAndTraits
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_110, _cache[95] || (_cache[95] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.OAIC_archetypes)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass(["your-sections-item", `${_ctx.hasOaicArchetypes ? '' : 'required'} ${
                          _ctx.canEdit ? '' : 'disable-true'
                        } ${_ctx.isLoadingByAi('OAIC_archetypes') ? 'ai-icon' : ''}`]),
                                  onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.canEdit && _ctx.setUpOAIC_Archetypes(_ctx.formId)))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Culture_Profile")), 1),
                                  _createElementVNode("div", _hoisted_111, [
                                    (_ctx.isLoadingByAi('OAIC_archetypes'))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_112, "AI"))
                                      : _createCommentVNode("", true),
                                    (_ctx.isLoadingByAi('OAIC_archetypes'))
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_113))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('OAIC_archetypes') &&
                              !_ctx.hasOaicArchetypes
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_114, _cache[96] || (_cache[96] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('OAIC_archetypes') &&
                              _ctx.hasOaicArchetypes
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_115, _cache[97] || (_cache[97] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.human_skills)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 3,
                                  class: _normalizeClass(["your-sections-item no-settings", `${_ctx.canEdit ? '' : 'disable-true'}`])
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Human_Skills")), 1),
                                  _createElementVNode("div", _hoisted_116, [
                                    false
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_117, _cache[98] || (_cache[98] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    true
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_118, _cache[99] || (_cache[99] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.acceptable_behaviors)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 4,
                                  class: _normalizeClass(["your-sections-item", `${_ctx.hasAcceptableBehaviors ? '' : 'required'} ${
                          _ctx.canEdit ? '' : 'disable-true'
                        } ${
                          _ctx.isLoadingByAi('acceptable_behaviors') ? 'ai-icon' : ''
                        }`]),
                                  onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.canEdit && _ctx.setUpAcceptableBehaviors(_ctx.formId)))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Acceptable_Behaviors")), 1),
                                  _createElementVNode("div", _hoisted_119, [
                                    (_ctx.isLoadingByAi('acceptable_behaviors'))
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_120, "AI"))
                                      : _createCommentVNode("", true),
                                    (_ctx.isLoadingByAi('acceptable_behaviors'))
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_121))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('acceptable_behaviors') &&
                              !_ctx.hasAcceptableBehaviors
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_122, _cache[100] || (_cache[100] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    (
                              !_ctx.isLoadingByAi('acceptable_behaviors') &&
                              _ctx.hasAcceptableBehaviors
                            )
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_123, _cache[101] || (_cache[101] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.work_style_identifier)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 5,
                                  class: _normalizeClass(["your-sections-item no-settings", `${_ctx.canEdit ? '' : 'disable-true'}`])
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Work_Style_Identifier")), 1),
                                  _createElementVNode("div", _hoisted_124, [
                                    false
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_125, _cache[102] || (_cache[102] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    true
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_126, _cache[103] || (_cache[103] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.logic_test)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 6,
                                  class: _normalizeClass(["your-sections-item no-settings", `${_ctx.canEdit ? '' : 'disable-true'}`])
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Logic_Test")), 1),
                                  _cache[104] || (_cache[104] = _createElementVNode("div", { class: "icon" }, [
                                    _createElementVNode("svg", {
                                      width: "12",
                                      height: "9",
                                      viewBox: "0 0 12 9",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                        fill: "black"
                                      })
                                    ])
                                  ], -1))
                                ], 2))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.custom_survey)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 7,
                                  class: _normalizeClass(["your-sections-item", `${_ctx.hasCustomSurvey ? '' : 'required'} ${
                          _ctx.canEdit ? '' : 'disable-true'
                        }`]),
                                  onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.canEdit && _ctx.setUpCustomSurvey(_ctx.formId)))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Custom_Survey")), 1),
                                  _createElementVNode("div", _hoisted_127, [
                                    (!_ctx.hasCustomSurvey)
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_128, _cache[105] || (_cache[105] = [
                                          _createElementVNode("path", {
                                            d: "M7.00065 13.6663C3.31875 13.6663 0.333984 10.6815 0.333984 6.99967C0.333984 3.31777 3.31875 0.333008 7.00065 0.333008C10.6825 0.333008 13.6673 3.31777 13.6673 6.99967C13.6673 10.6815 10.6825 13.6663 7.00065 13.6663ZM7.00065 12.333C9.94618 12.333 12.334 9.94521 12.334 6.99967C12.334 4.05415 9.94618 1.66634 7.00065 1.66634C4.05513 1.66634 1.66732 4.05415 1.66732 6.99967C1.66732 9.94521 4.05513 12.333 7.00065 12.333ZM6.33398 8.99967H7.66732V10.333H6.33398V8.99967ZM6.33398 3.66634H7.66732V7.66634H6.33398V3.66634Z",
                                            fill: "#FF523C"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true),
                                    (_ctx.hasCustomSurvey)
                                      ? (_openBlock(), _createElementBlock("svg", _hoisted_129, _cache[106] || (_cache[106] = [
                                          _createElementVNode("path", {
                                            d: "M4.66648 7.11361L10.7947 0.985352L11.7375 1.92816L4.66648 8.99921L0.423828 4.7566L1.36663 3.81381L4.66648 7.11361Z",
                                            fill: "black"
                                          }, null, -1)
                                        ])))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 2))
                              : _createCommentVNode("", true)
                          ])
                        ], 4)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "popover-content",
                          innerHTML: _ctx.$t('guided_tour.create.step_2')
                        }, null, 8, _hoisted_130)
                      ]),
                      _: 1
                    }, 8, ["visible"]))
                  : _createCommentVNode("", true),
                (_ctx.step == 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_131, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.yourSelectedItemsTotal) + " Selected", 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_132, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectionListStepTwo, (section) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: section.keyValue,
                          class: "box-card box-step-two"
                        }, [
                          _createElementVNode("div", _hoisted_133, [
                            _createElementVNode("div", _hoisted_134, [
                              _cache[107] || (_cache[107] = _createElementVNode("img", {
                                src: _imports_6,
                                class: "icon-16 mr-7 opa-05",
                                alt: "icons_timer_20px"
                              }, null, -1)),
                              _createElementVNode("div", null, _toDisplayString(section.timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_135, _toDisplayString(section.modelType), 1)
                          ]),
                          _createElementVNode("div", _hoisted_136, _toDisplayString(_ctx.$t("create." + section.title)), 1),
                          _createElementVNode("div", _hoisted_137, [
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.description, (des, i2) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: des.label + i2,
                                  class: _normalizeClass(des.class)
                                }, _toDisplayString(_ctx.$t("create." + des.label)) + " ", 3))
                              }), 128))
                            ]),
                            _cache[108] || (_cache[108] = _createElementVNode("div", null, " ", -1)),
                            (section.description_end)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_138, _toDisplayString(_ctx.$t("create." + section.description_end)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_139, [
                            false
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.onClickSection(section.keyValue)),
                                  class: _normalizeClass(["ml-auto checkbox-section flex-mid", { checked: (_ctx.form.sections as any)[section.keyValue] }])
                                }, [
                                  _createElementVNode("div", _hoisted_141, _toDisplayString(_ctx.$t("create.Select")), 1),
                                  _createElementVNode("div", _hoisted_142, [
                                    _cache[109] || (_cache[109] = _createElementVNode("img", {
                                      src: _imports_4,
                                      class: "icon false",
                                      alt: "icons_white_block_32px"
                                    }, null, -1)),
                                    _createElementVNode("div", _hoisted_143, _toDisplayString(_ctx.$t("create.Selected")), 1)
                                  ])
                                ], 10, _hoisted_140))
                              : _createCommentVNode("", true),
                            ((_ctx.form.sections as any)[section.keyValue])
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass(["btn-romove-model", _ctx.canEdit ? '' : 'disable-true']),
                                  onClick: ($event: any) => (_ctx.canEdit && _ctx.onClickSection(section.keyValue))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("create.Remove")), 1)
                                ], 10, _hoisted_144))
                              : _createCommentVNode("", true),
                            (!(_ctx.form.sections as any)[section.keyValue])
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass(["btn btn-add", _ctx.canEdit ? '' : 'disable-true']),
                                  onClick: ($event: any) => (_ctx.canEdit && _ctx.onClickSection(section.keyValue))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Add")), 1)
                                ], 10, _hoisted_145))
                              : _createCommentVNode("", true),
                            (!(_ctx.form.sections as any)[section.keyValue])
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 3,
                                  class: "btn btn-detail",
                                  onClick: ($event: any) => (_ctx.openDetailDialog(section.keyValue))
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Detail")), 1)
                                ], 8, _hoisted_146))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 3)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_147, [
                      (_ctx.AI_ASSISTED_SETUP)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_148, [
                            _createElementVNode("div", _hoisted_149, _toDisplayString(_ctx.$t("create.Your_Core_Values")), 1),
                            _createElementVNode("div", _hoisted_150, _toDisplayString(_ctx.$t("create.Define_your_organizational")), 1),
                            _createElementVNode("div", null, [
                              _createVNode(_component_el_select, {
                                modelValue: _ctx.form.coreValue,
                                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.form.coreValue) = $event)),
                                placeholder: _ctx.$t('create.Quick_Select_or_Add'),
                                class: _normalizeClass(`input-44-0000000d select no-icon mt-24 tag-primary-color`),
                                style: {"height":"auto"},
                                multiple: "",
                                "allow-create": "",
                                filterable: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coreValueTags, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.id,
                                      label: item.label,
                                      value: item.label
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "placeholder"])
                            ]),
                            _createElementVNode("div", _hoisted_151, [
                              _createElementVNode("div", {
                                onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.setupByAI())),
                                class: _normalizeClass(["btn btn-44 btn-primary", `${
                        _ctx.coreValueEnabled ? 'btn-primary' : 'disable-true'
                      } ${
                        _ctx.setupByAILoadingStatus.values_and_traits ||
                        _ctx.setupByAILoadingStatus.OAIC_archetypes
                          ? 'disable-true'
                          : ''
                      }`]),
                                style: _normalizeStyle(`width: ${
                        _ctx.$t('lang_code') == 'th' ? '22' : '22'
                      }rem`)
                              }, [
                                _cache[110] || (_cache[110] = _createElementVNode("img", {
                                  src: _imports_7,
                                  alt: "icons_white_ai_assist_24dp",
                                  class: "mr-16"
                                }, null, -1)),
                                _createElementVNode("span", _hoisted_152, _toDisplayString(_ctx.$t("create.Start_AI_Assisted_Setup")), 1)
                              ], 6),
                              _createElementVNode("div", {
                                onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.proceedWithManualSetup && _ctx.proceedWithManualSetup(...args))),
                                class: "fc-primary link ml-32 fs-16"
                              }, _toDisplayString(_ctx.$t("create.Proceed_with_Manual_Setup")), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.AI_ASSISTED_SETUP)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_153, null, 512))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.values_and_traits)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_154, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_155, _toDisplayString(_ctx.$t("create.Values_Alignment")), 1),
                              _createElementVNode("div", _hoisted_156, _toDisplayString(_ctx.$t("create.Setup_your_company_values")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_157, [
                              (_ctx.setupByAILoadingStatus.values_and_traits)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_158, [
                                    _cache[111] || (_cache[111] = _createElementVNode("svg", {
                                      version: "1.1",
                                      id: "L9",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                      x: "0px",
                                      y: "0px",
                                      viewBox: "0 0 100 100",
                                      "enable-background": "new 0 0 0 0",
                                      "xml:space": "preserve"
                                    }, [
                                      _createElementVNode("path", {
                                        fill: "#fff",
                                        d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                      }, [
                                        _createElementVNode("animateTransform", {
                                          attributeName: "transform",
                                          attributeType: "XML",
                                          type: "rotate",
                                          dur: "1s",
                                          from: "0 50 50",
                                          to: "360 50 50",
                                          repeatCount: "indefinite"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("span", _hoisted_159, _toDisplayString(_ctx.$t("create.Generate")), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.setUpValuesAndTraitsNewTab(_ctx.formId))),
                                    class: _normalizeClass(["checkbox-set-up flex-center", { checked: _ctx.hasValuesAndTraits }])
                                  }, [
                                    (_ctx.hasValuesAndTraits)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_160, [
                                          _cache[112] || (_cache[112] = _createElementVNode("img", {
                                            src: _imports_8,
                                            class: "icon-24",
                                            alt: "icons_white_ready_32px"
                                          }, null, -1)),
                                          _createElementVNode("div", _hoisted_161, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_162, [
                                          _cache[113] || (_cache[113] = _createElementVNode("img", {
                                            src: _imports_9,
                                            class: "icon-24",
                                            alt: "icons_white_warning_32px"
                                          }, null, -1)),
                                          (_ctx.setupByAIStatus.values_and_traits)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_163, _toDisplayString(_ctx.$t("create.Pending_Review")), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_164, _toDisplayString(_ctx.$t("create.Set_up")), 1))
                                        ]))
                                  ], 2))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.OAIC_archetypes)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_165, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_166, _toDisplayString(_ctx.$t("create.Culture_Profile")), 1),
                              _createElementVNode("div", _hoisted_167, _toDisplayString(_ctx.$t("create.Setup_your_culture_profile")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_168, [
                              (_ctx.setupByAILoadingStatus.OAIC_archetypes)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_169, [
                                    _cache[114] || (_cache[114] = _createElementVNode("svg", {
                                      version: "1.1",
                                      id: "L9",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                      x: "0px",
                                      y: "0px",
                                      viewBox: "0 0 100 100",
                                      "enable-background": "new 0 0 0 0",
                                      "xml:space": "preserve"
                                    }, [
                                      _createElementVNode("path", {
                                        fill: "#fff",
                                        d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                      }, [
                                        _createElementVNode("animateTransform", {
                                          attributeName: "transform",
                                          attributeType: "XML",
                                          type: "rotate",
                                          dur: "1s",
                                          from: "0 50 50",
                                          to: "360 50 50",
                                          repeatCount: "indefinite"
                                        })
                                      ])
                                    ], -1)),
                                    _createElementVNode("span", _hoisted_170, _toDisplayString(_ctx.$t("create.Generate")), 1)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.setUpOAIC_ArchetypesNewTab(_ctx.formId))),
                                    class: _normalizeClass(["checkbox-set-up flex-center", { checked: _ctx.hasOaicArchetypes }])
                                  }, [
                                    (_ctx.hasOaicArchetypes)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_171, [
                                          _cache[115] || (_cache[115] = _createElementVNode("img", {
                                            src: _imports_8,
                                            class: "icon-24",
                                            alt: "icons_white_ready_32px"
                                          }, null, -1)),
                                          _createElementVNode("div", _hoisted_172, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_173, [
                                          _cache[116] || (_cache[116] = _createElementVNode("img", {
                                            src: _imports_9,
                                            class: "icon-24",
                                            alt: "icons_white_warning_32px"
                                          }, null, -1)),
                                          (_ctx.setupByAIStatus.OAIC_archetypes)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_174, _toDisplayString(_ctx.$t("create.Pending_Review")), 1))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_175, _toDisplayString(_ctx.$t("create.Set_up")), 1))
                                        ]))
                                  ], 2))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.acceptable_behaviors)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_176, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_177, _toDisplayString(_ctx.$t("create.Acceptable_Behaviors")), 1),
                              _createElementVNode("div", _hoisted_178, _toDisplayString(_ctx.$t("create.Setup_your_acceptable_behaviors")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_179, [
                              _createElementVNode("div", {
                                onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.setUpAcceptableBehaviorsNewTab(_ctx.formId))),
                                class: _normalizeClass(["checkbox-set-up flex-center", { checked: _ctx.hasAcceptableBehaviors }])
                              }, [
                                (_ctx.hasAcceptableBehaviors)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_180, [
                                      _cache[117] || (_cache[117] = _createElementVNode("img", {
                                        src: _imports_8,
                                        class: "icon-24",
                                        alt: "icons_white_ready_32px"
                                      }, null, -1)),
                                      _createElementVNode("div", _hoisted_181, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_182, [
                                      _cache[118] || (_cache[118] = _createElementVNode("img", {
                                        src: _imports_9,
                                        class: "icon-24",
                                        alt: "icons_white_warning_32px"
                                      }, null, -1)),
                                      _createElementVNode("div", _hoisted_183, _toDisplayString(_ctx.$t("create.Set_up")), 1)
                                    ]))
                              ], 2)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.human_skills)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_184, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_185, _toDisplayString(_ctx.$t("create.Human_Skills")), 1),
                              _createElementVNode("div", _hoisted_186, _toDisplayString(_ctx.$t("create.No_set_up_required_to_assess_written")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_187, [
                              _createElementVNode("div", _hoisted_188, [
                                _createElementVNode("div", _hoisted_189, [
                                  _cache[119] || (_cache[119] = _createElementVNode("img", {
                                    src: _imports_8,
                                    class: "icon-24",
                                    alt: "icons_white_ready_32px"
                                  }, null, -1)),
                                  _createElementVNode("div", _hoisted_190, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.work_style_identifier)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_191, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_192, _toDisplayString(_ctx.$t("create.Work_Style_Identifier")), 1),
                              _createElementVNode("div", _hoisted_193, _toDisplayString(_ctx.$t("create.No_set_up_required")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_194, [
                              _createElementVNode("div", _hoisted_195, [
                                _createElementVNode("div", _hoisted_196, [
                                  _cache[120] || (_cache[120] = _createElementVNode("img", {
                                    src: _imports_8,
                                    class: "icon-24",
                                    alt: "icons_white_ready_32px"
                                  }, null, -1)),
                                  _createElementVNode("div", _hoisted_197, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.sections.custom_survey)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_198, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_199, _toDisplayString(_ctx.$t("create.Custom_Survey")), 1),
                              _createElementVNode("div", _hoisted_200, _toDisplayString(_ctx.$t("create.Setup_your_acceptable_behaviors")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_201, [
                              _createElementVNode("div", {
                                onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.setUpCustomSurveyNewTab(_ctx.formId))),
                                class: _normalizeClass(["checkbox-set-up flex-center", { checked: _ctx.hasCustomSurvey }])
                              }, [
                                (_ctx.hasCustomSurvey)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_202, [
                                      _cache[121] || (_cache[121] = _createElementVNode("img", {
                                        src: _imports_8,
                                        class: "icon-24",
                                        alt: "icons_white_ready_32px"
                                      }, null, -1)),
                                      _createElementVNode("div", _hoisted_203, _toDisplayString(_ctx.$t("create.Ready")), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_204, [
                                      _cache[122] || (_cache[122] = _createElementVNode("img", {
                                        src: _imports_9,
                                        class: "icon-24",
                                        alt: "icons_white_warning_32px"
                                      }, null, -1)),
                                      _createElementVNode("div", _hoisted_205, _toDisplayString(_ctx.$t("create.Set_up")), 1)
                                    ]))
                              ], 2)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_206, [
                      _createElementVNode("div", _hoisted_207, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_208, [
                            _createElementVNode("div", _hoisted_209, _toDisplayString(_ctx.$t("create.Review_and_Publish")), 1),
                            _createElementVNode("div", _hoisted_210, [
                              _createTextVNode(_toDisplayString(_ctx.$t("create.Carefully_check_all")) + " ", 1),
                              _cache[123] || (_cache[123] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("create.If_something_is_incorrect")), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_211, [
                            _createElementVNode("div", _hoisted_212, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t("Name")), 1),
                              _createTextVNode(_toDisplayString(_ctx.form.name), 1)
                            ]),
                            _createElementVNode("div", _hoisted_213, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t("Purpose")), 1),
                              _createTextVNode(_toDisplayString(_ctx.getPurposeDescription()), 1)
                            ]),
                            _createElementVNode("div", _hoisted_214, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t("Language")), 1),
                              _createTextVNode(_toDisplayString(_ctx.langRequiredLabel), 1)
                            ]),
                            _createElementVNode("div", _hoisted_215, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t("create.Sections")), 1),
                              _createTextVNode(_toDisplayString(_ctx.langSections), 1)
                            ])
                          ])
                        ])
                      ]),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_216, [
                            _createElementVNode("div", _hoisted_217, [
                              _createElementVNode("div", _hoisted_218, _toDisplayString(_ctx.$t("create.SECTIONS")), 1),
                              _createElementVNode("div", _hoisted_219, _toDisplayString(_ctx.$t("create.DURATION")), 1)
                            ]),
                            (_ctx.form.sections.values_and_traits)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_220, [
                                  _createElementVNode("div", _hoisted_221, _toDisplayString(_ctx.$t("create.Values_Alignment")), 1),
                                  _createElementVNode("div", _hoisted_222, [
                                    _cache[124] || (_cache[124] = _createElementVNode("img", {
                                      src: _imports_6,
                                      class: "icon-16 mr-7 opa-05",
                                      alt: "icons_timer_20px"
                                    }, null, -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.extraTimeAdd + 5) + "'", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.OAIC_archetypes)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_223, [
                                  _createElementVNode("div", _hoisted_224, _toDisplayString(_ctx.$t("create.Culture_Profile")), 1),
                                  _createElementVNode("div", _hoisted_225, [
                                    _cache[125] || (_cache[125] = _createElementVNode("img", {
                                      src: _imports_6,
                                      class: "icon-16 mr-7 opa-05",
                                      alt: "icons_timer_20px"
                                    }, null, -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.extraTimeAdd + 5) + "'", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.acceptable_behaviors)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_226, [
                                  _createElementVNode("div", _hoisted_227, _toDisplayString(_ctx.$t("create.Acceptable_Behaviors")), 1),
                                  _createElementVNode("div", _hoisted_228, [
                                    _cache[126] || (_cache[126] = _createElementVNode("img", {
                                      src: _imports_6,
                                      class: "icon-16 mr-7 opa-05",
                                      alt: "icons_timer_20px"
                                    }, null, -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.extraTimeAdd + 5) + "'", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.human_skills)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_229, [
                                  _createElementVNode("div", _hoisted_230, _toDisplayString(_ctx.$t("create.Human_Skills")), 1),
                                  _createElementVNode("div", _hoisted_231, [
                                    _cache[127] || (_cache[127] = _createElementVNode("img", {
                                      src: _imports_6,
                                      class: "icon-16 mr-7 opa-05",
                                      alt: "icons_timer_20px"
                                    }, null, -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.extraTimeAdd + 10) + "'", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.form.sections.work_style_identifier)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_232, [
                                  _createElementVNode("div", _hoisted_233, _toDisplayString(_ctx.$t("create.Work_Style_Identifier")), 1),
                                  _createElementVNode("div", _hoisted_234, [
                                    _cache[128] || (_cache[128] = _createElementVNode("img", {
                                      src: _imports_6,
                                      class: "icon-16 mr-7 opa-05",
                                      alt: "icons_timer_20px"
                                    }, null, -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.extraTimeAdd + 5) + "'", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_235, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_236, _toDisplayString(_ctx.$t("create.Advanced_Options")), 1),
                            _createElementVNode("div", _hoisted_237, _toDisplayString(_ctx.$t("create.Additional_configurations_to")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_238, [
                            _createElementVNode("div", _hoisted_239, [
                              _createElementVNode("div", _hoisted_240, _toDisplayString(_ctx.$t("create.Extra_Time")), 1),
                              _createElementVNode("div", _hoisted_241, [
                                _createVNode(_component_el_select, {
                                  modelValue: _ctx.form.extraTime,
                                  "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.form.extraTime) = $event)),
                                  class: "select-login e-input-52",
                                  style: {"width":"164px"},
                                  placeholder: _ctx.$t('create.Select')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_option, {
                                      label: '0 ' + _ctx.$t('create.min'),
                                      value: "0"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_option, {
                                      label: '1 ' + _ctx.$t('create.min'),
                                      value: "1"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_option, {
                                      label: '2 ' + _ctx.$t('create.min'),
                                      value: "2"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_option, {
                                      label: '3 ' + _ctx.$t('create.min'),
                                      value: "3"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_option, {
                                      label: '4 ' + _ctx.$t('create.min'),
                                      value: "4"
                                    }, null, 8, ["label"]),
                                    _createVNode(_component_el_option, {
                                      label: '5 ' + _ctx.$t('create.min'),
                                      value: "5"
                                    }, null, 8, ["label"])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "placeholder"])
                              ]),
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_242, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.form.extraTime || 0) + " min", 1),
                                  _createVNode(_component_el_dropdown, null, {
                                    dropdown: _withCtx(() => [
                                      _createVNode(_component_el_dropdown_menu, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[39] || (_cache[39] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 0)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 0 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[40] || (_cache[40] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 1)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 1 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[41] || (_cache[41] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 2)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 2 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[42] || (_cache[42] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 3)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 3 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[43] || (_cache[43] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 4)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 4 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_dropdown_item, {
                                            onClick: _cache[44] || (_cache[44] = ($event: any) => (
                                      _ctx.handleCommand('setExtraTime', 5)
                                    ))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" 5 " + _toDisplayString(_ctx.$t("create.min")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[129] || (_cache[129] = _createElementVNode("svg", {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M10.4853 6.38322L9.54247 5.4404L3.33333 11.6496V12.5924H4.27614L10.4853 6.38322ZM11.4281 5.4404L12.3709 4.4976L11.4281 3.55479L10.4853 4.4976L11.4281 5.4404ZM4.82843 13.9257H2V11.0973L10.9567 2.14058C11.2171 1.88022 11.6391 1.88022 11.8995 2.14058L13.7851 4.02619C14.0455 4.28654 14.0455 4.70865 13.7851 4.969L4.82843 13.9257Z",
                                          fill: "black"
                                        })
                                      ], -1))
                                    ]),
                                    _: 1
                                  })
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_243, [
                              _createElementVNode("div", _hoisted_244, _toDisplayString(_ctx.$t("create.Sharing_Results")), 1),
                              _createElementVNode("div", _hoisted_245, [
                                _createElementVNode("label", null, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.form.sharingResults) = $event)),
                                    type: "checkbox",
                                    value: "checkbox"
                                  }, null, 512), [
                                    [_vModelCheckbox, _ctx.form.sharingResults]
                                  ]),
                                  _createElementVNode("span", _hoisted_246, _toDisplayString(_ctx.$t("create.Auto_send_assessment")), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_247, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.form.sharingResults ? "Yes" : "No")), 1),
                                _createVNode(_component_el_dropdown, null, {
                                  dropdown: _withCtx(() => [
                                    _createVNode(_component_el_dropdown_menu, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_dropdown_item, {
                                          onClick: _cache[46] || (_cache[46] = ($event: any) => (
                                    _ctx.handleCommand('setSharingResults', true)
                                  ))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("Yes")), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_dropdown_item, {
                                          onClick: _cache[47] || (_cache[47] = ($event: any) => (
                                    _ctx.handleCommand('setSharingResults', false)
                                  ))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("No")), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[130] || (_cache[130] = _createElementVNode("svg", {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M10.4853 6.38322L9.54247 5.4404L3.33333 11.6496V12.5924H4.27614L10.4853 6.38322ZM11.4281 5.4404L12.3709 4.4976L11.4281 3.55479L10.4853 4.4976L11.4281 5.4404ZM4.82843 13.9257H2V11.0973L10.9567 2.14058C11.2171 1.88022 11.6391 1.88022 11.8995 2.14058L13.7851 4.02619C14.0455 4.28654 14.0455 4.70865 13.7851 4.969L4.82843 13.9257Z",
                                        fill: "black"
                                      })
                                    ], -1))
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_248, [
                              _createElementVNode("div", _hoisted_249, _toDisplayString(_ctx.$t("create.Allow_Comments")), 1),
                              _createElementVNode("div", _hoisted_250, [
                                _createElementVNode("label", null, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.form.openEndedQuestion) = $event)),
                                    type: "checkbox",
                                    value: "checkbox"
                                  }, null, 512), [
                                    [_vModelCheckbox, _ctx.form.openEndedQuestion]
                                  ]),
                                  _createElementVNode("span", _hoisted_251, _toDisplayString(_ctx.$t("create.Allow_respondents_to")), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_252, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.form.openEndedQuestion ? "Yes" : "No")), 1),
                                _createVNode(_component_el_dropdown, null, {
                                  dropdown: _withCtx(() => [
                                    _createVNode(_component_el_dropdown_menu, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_dropdown_item, {
                                          onClick: _cache[49] || (_cache[49] = ($event: any) => (
                                    _ctx.handleCommand('setOpenEndedQuestion', true)
                                  ))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("Yes")), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_el_dropdown_item, {
                                          onClick: _cache[50] || (_cache[50] = ($event: any) => (
                                    _ctx.handleCommand('setOpenEndedQuestion', false)
                                  ))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("No")), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  default: _withCtx(() => [
                                    _cache[131] || (_cache[131] = _createElementVNode("svg", {
                                      width: "16",
                                      height: "16",
                                      viewBox: "0 0 16 16",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }, [
                                      _createElementVNode("path", {
                                        d: "M10.4853 6.38322L9.54247 5.4404L3.33333 11.6496V12.5924H4.27614L10.4853 6.38322ZM11.4281 5.4404L12.3709 4.4976L11.4281 3.55479L10.4853 4.4976L11.4281 5.4404ZM4.82843 13.9257H2V11.0973L10.9567 2.14058C11.2171 1.88022 11.6391 1.88022 11.8995 2.14058L13.7851 4.02619C14.0455 4.28654 14.0455 4.70865 13.7851 4.969L4.82843 13.9257Z",
                                        fill: "black"
                                      })
                                    ], -1))
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_253, [
                                _createElementVNode("div", _hoisted_254, _toDisplayString(_ctx.$t("create.Redirect_respondents_to")), 1),
                                _createElementVNode("div", _hoisted_255, [
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.form.redirectUrl) = $event)),
                                    class: _normalizeClass(`input-44-0000000d ${_ctx.checkingItIsURL(
                                _ctx.form.redirectUrl
                              )}`),
                                    style: {"max-width":"40rem","font-size":"1.5rem"},
                                    placeholder: "https://myculture.ai/thank-you-for-submitting",
                                    type: "text"
                                  }, null, 2), [
                                    [_vModelText, _ctx.form.redirectUrl]
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_256, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.redirectUrlName), 1),
                                  (_openBlock(), _createElementBlock("svg", {
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 16 16",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    onClick: _cache[52] || (_cache[52] = ($event: any) => (
                                _ctx.isEditRedirectLink = !_ctx.isEditRedirectLink
                              )),
                                    style: {"cursor":"pointer"}
                                  }, _cache[132] || (_cache[132] = [
                                    _createElementVNode("path", {
                                      d: "M10.4853 6.38322L9.54247 5.4404L3.33333 11.6496V12.5924H4.27614L10.4853 6.38322ZM11.4281 5.4404L12.3709 4.4976L11.4281 3.55479L10.4853 4.4976L11.4281 5.4404ZM4.82843 13.9257H2V11.0973L10.9567 2.14058C11.2171 1.88022 11.6391 1.88022 11.8995 2.14058L13.7851 4.02619C14.0455 4.28654 14.0455 4.70865 13.7851 4.969L4.82843 13.9257Z",
                                      fill: "black"
                                    }, null, -1)
                                  ])))
                                ])
                              ]),
                              (_ctx.isEditRedirectLink)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_257, [
                                    _createElementVNode("div", _hoisted_258, [
                                      _withDirectives(_createElementVNode("input", {
                                        "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.redirectUrl) = $event)),
                                        class: _normalizeClass(`input ${_ctx.checkingItIsURL(_ctx.redirectUrl)}`),
                                        style: {"max-width":"40rem","font-size":"1.5rem"},
                                        placeholder: "https://myculture.ai/thank-you-for-submitting",
                                        type: "text"
                                      }, null, 2), [
                                        [_vModelText, _ctx.redirectUrl]
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_259, [
                                      _createElementVNode("div", {
                                        class: "btn02 btn-previous",
                                        onClick: _cache[54] || (_cache[54] = ($event: any) => (_ctx.isEditRedirectLink = false))
                                      }, _toDisplayString(_ctx.$t("btn.Cancel")), 1),
                                      _createElementVNode("div", {
                                        class: "btn02 btn-primary",
                                        onClick: _cache[55] || (_cache[55] = 
//@ts-ignore
(...args) => (_ctx.saveRedirectUrl && _ctx.saveRedirectUrl(...args)))
                                      }, _toDisplayString(_ctx.$t("btn.Save")), 1)
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_260, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.setUpValuesAndTraitsModalStatus,
        "onUpdate:modelValue": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.setUpValuesAndTraitsModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "51.7rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_261, [
            _createElementVNode("div", _hoisted_262, _toDisplayString(_ctx.$t("setup.valueAndTraits.Set_up_Values_Alignment")), 1),
            _createElementVNode("div", _hoisted_263, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.valueAndTraits.Identify_your_values")) + " ", 1),
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedExistsTemplateValues,
                "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.selectedExistsTemplateValues) = $event)),
                class: "ep-select-44",
                style: {},
                "x-placeholder": _ctx.$t('setup.Proceed_with_a_new_setup')
              }, {
                default: _withCtx(() => [
                  (_ctx.hasValuesAndTraits)
                    ? (_openBlock(), _createBlock(_component_el_option, {
                        key: _ctx.$t('setup.Resume_setup'),
                        label: 
                _ctx.$t('setup.Auto_generated_for_form_name', {
                  formName: _ctx.form.name,
                })
              ,
                        value: ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_component_el_option, {
                    key: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    label: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    value: "0"
                  }, null, 8, ["label"])),
                  (_ctx.existsTemplates.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_option_group, {
                        key: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment'),
                        label: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item._id,
                              label: item.form_name,
                              value: item._id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "x-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_264, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-48 btn-primary", { 'disable-true': false }]),
                onClick: _cache[57] || (_cache[57] = ($event: any) => (_ctx.setUpValuesAndTraitsNewTab(_ctx.formId))),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Continue")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn btn-48 btn-default",
                onClick: _cache[58] || (_cache[58] = ($event: any) => (_ctx.setUpValuesAndTraitsModalStatus = false)),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_265, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.setUpOAIC_ArchetypesModalStatus,
        "onUpdate:modelValue": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.setUpOAIC_ArchetypesModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "51.7rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_266, [
            _createElementVNode("div", _hoisted_267, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_your_Culture_Profile")), 1),
            _createElementVNode("div", _hoisted_268, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.cultureProfile.The_Happily_Culture_Profile")) + " ", 1),
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedExistsTemplateOAIC_Archetypes,
                "onUpdate:modelValue": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.selectedExistsTemplateOAIC_Archetypes) = $event)),
                class: "ep-select-44",
                style: {},
                "x-placeholder": _ctx.$t('setup.Proceed_with_a_new_setup')
              }, {
                default: _withCtx(() => [
                  (_ctx.hasOaicArchetypes)
                    ? (_openBlock(), _createBlock(_component_el_option, {
                        key: _ctx.$t('setup.Resume_setup'),
                        label: 
                _ctx.$t('setup.Auto_generated_for_form_name', {
                  formName: _ctx.form.name,
                })
              ,
                        value: ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_component_el_option, {
                    key: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    label: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    value: "0"
                  }, null, 8, ["label"])),
                  (_ctx.existsTemplates.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_option_group, {
                        key: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment'),
                        label: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item._id,
                              label: item.form_name,
                              value: item._id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "x-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_269, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-48 btn-primary", { 'disable-true': false }]),
                onClick: _cache[61] || (_cache[61] = ($event: any) => (_ctx.setUpOAIC_ArchetypesNewTab(_ctx.formId))),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Continue")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn btn-48 btn-default",
                onClick: _cache[62] || (_cache[62] = ($event: any) => (_ctx.setUpOAIC_ArchetypesModalStatus = false)),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_270, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.setUpAcceptableBehaviorsModalStatus,
        "onUpdate:modelValue": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.setUpAcceptableBehaviorsModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "51.7rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_271, [
            _createElementVNode("div", _hoisted_272, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_your_Values")), 1),
            _createElementVNode("div", _hoisted_273, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.acceptableBehaviors.Identify_areas_of_misalignment")) + " ", 1),
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedExistsTemplateAcceptableBehaviors,
                "onUpdate:modelValue": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.selectedExistsTemplateAcceptableBehaviors) = $event)),
                class: "ep-select-44",
                style: {},
                "x-placeholder": _ctx.$t('setup.Proceed_with_a_new_setup')
              }, {
                default: _withCtx(() => [
                  (_ctx.hasAcceptableBehaviors)
                    ? (_openBlock(), _createBlock(_component_el_option, {
                        key: _ctx.$t('setup.Resume_setup'),
                        label: 
                _ctx.$t('setup.Auto_generated_for_form_name', {
                  formName: _ctx.form.name,
                })
              ,
                        value: ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_component_el_option, {
                    key: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    label: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    value: "0"
                  }, null, 8, ["label"])),
                  (_ctx.existsTemplates.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_option_group, {
                        key: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment'),
                        label: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item._id,
                              label: item.form_name,
                              value: item._id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "x-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_274, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-48 btn-primary", { 'disable-true': false }]),
                onClick: _cache[65] || (_cache[65] = ($event: any) => (_ctx.setUpAcceptableBehaviorsNewTab(_ctx.formId))),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Continue")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn btn-48 btn-default",
                onClick: _cache[66] || (_cache[66] = ($event: any) => (_ctx.setUpAcceptableBehaviorsModalStatus = false)),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_275, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.setUpCustomSurveyModalStatus,
        "onUpdate:modelValue": _cache[71] || (_cache[71] = ($event: any) => ((_ctx.setUpCustomSurveyModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "51.7rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_276, [
            _createElementVNode("div", _hoisted_277, _toDisplayString(_ctx.$t("setup.customSurvey.Set_up_Custom_Survey")), 1),
            _createElementVNode("div", _hoisted_278, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.valueAndTraits.Identify_your_values")) + " ", 1),
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedExistsTemplateCustomSurvey,
                "onUpdate:modelValue": _cache[68] || (_cache[68] = ($event: any) => ((_ctx.selectedExistsTemplateCustomSurvey) = $event)),
                class: "ep-select-44",
                style: {},
                "x-placeholder": _ctx.$t('setup.Proceed_with_a_new_setup')
              }, {
                default: _withCtx(() => [
                  (_ctx.hasCustomSurvey)
                    ? (_openBlock(), _createBlock(_component_el_option, {
                        key: _ctx.$t('setup.Resume_setup'),
                        label: 
                _ctx.$t('setup.Auto_generated_for_form_name', {
                  formName: _ctx.form.name,
                })
              ,
                        value: ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_component_el_option, {
                    key: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    label: _ctx.$t('setup.Proceed_with_a_new_setup'),
                    value: "0"
                  }, null, 8, ["label"])),
                  (_ctx.existsTemplates.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_option_group, {
                        key: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment'),
                        label: _ctx.$t('setup.Use_the_same_setup_as_a_previous_assessment')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item._id,
                              label: item.form_name,
                              value: item._id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "x-placeholder"])
            ]),
            _createElementVNode("div", _hoisted_279, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn btn-48 btn-primary", { 'disable-true': false }]),
                onClick: _cache[69] || (_cache[69] = ($event: any) => (_ctx.setUpCustomSurveyNewTab(_ctx.formId))),
                style: {"width":"11rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Continue")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn btn-48 btn-default",
                onClick: _cache[70] || (_cache[70] = ($event: any) => (_ctx.setUpCustomSurveyModalStatus = false)),
                style: {"width":"7rem"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_280, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.valuesDetailModalStatus,
        "onUpdate:modelValue": _cache[72] || (_cache[72] = ($event: any) => ((_ctx.valuesDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_281, [
            _createElementVNode("div", _hoisted_282, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_283, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[0].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[133] || (_cache[133] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[0].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_284, _toDisplayString(_ctx.$t("setup.valueAndTraits.Setup_your_Values")), 1)
              ]),
              _createElementVNode("div", _hoisted_285, [
                _createElementVNode("div", _hoisted_286, [
                  _createElementVNode("div", _hoisted_287, _toDisplayString(_ctx.$t("setup.valueAndTraits.Description")), 1),
                  _createElementVNode("div", _hoisted_288, _toDisplayString(_ctx.$t("setup.valueAndTraits.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_289, [
                  _createElementVNode("div", _hoisted_290, _toDisplayString(_ctx.$t("setup.valueAndTraits.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.valueAndTraits.Full_Details'))
                  }, null, 8, _hoisted_291)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_292, [
              _createElementVNode("div", _hoisted_293, [
                _createElementVNode("div", _hoisted_294, [
                  _createElementVNode("div", _hoisted_295, _toDisplayString(_ctx.$t("setup.valueAndTraits.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_296, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.valueAndTraits.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[134] || (_cache[134] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_297, [
                  _createElementVNode("div", _hoisted_298, _toDisplayString(_ctx.$t("setup.valueAndTraits.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_299, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.valueAndTraits.This_test_is_relevant_for_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[135] || (_cache[135] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_300, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.cultureProfileDetailModalStatus,
        "onUpdate:modelValue": _cache[73] || (_cache[73] = ($event: any) => ((_ctx.cultureProfileDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_301, [
            _createElementVNode("div", _hoisted_302, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_303, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[1].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[136] || (_cache[136] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[1].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_304, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_your_Culture_Profile")), 1)
              ]),
              _createElementVNode("div", _hoisted_305, [
                _createElementVNode("div", _hoisted_306, [
                  _createElementVNode("div", _hoisted_307, _toDisplayString(_ctx.$t("setup.cultureProfile.Description")), 1),
                  _createElementVNode("div", _hoisted_308, _toDisplayString(_ctx.$t("setup.cultureProfile.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_309, [
                  _createElementVNode("div", _hoisted_310, _toDisplayString(_ctx.$t("setup.cultureProfile.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.cultureProfile.Full_Details'))
                  }, null, 8, _hoisted_311)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_312, [
              _createElementVNode("div", _hoisted_313, [
                _createElementVNode("div", _hoisted_314, [
                  _createElementVNode("div", _hoisted_315, _toDisplayString(_ctx.$t("setup.cultureProfile.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_316, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.cultureProfile.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[137] || (_cache[137] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_317, [
                  _createElementVNode("div", _hoisted_318, _toDisplayString(_ctx.$t("setup.cultureProfile.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_319, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.cultureProfile.This_test_is_relevant_for_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[138] || (_cache[138] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_320, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.humanSkillsDetailModalStatus,
        "onUpdate:modelValue": _cache[74] || (_cache[74] = ($event: any) => ((_ctx.humanSkillsDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_321, [
            _createElementVNode("div", _hoisted_322, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_323, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[2].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[139] || (_cache[139] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[2].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_324, _toDisplayString(_ctx.$t("main.Human_Skills")), 1)
              ]),
              _createElementVNode("div", _hoisted_325, [
                _createElementVNode("div", _hoisted_326, [
                  _createElementVNode("div", _hoisted_327, _toDisplayString(_ctx.$t("setup.humanSkills.Description")), 1),
                  _createElementVNode("div", _hoisted_328, _toDisplayString(_ctx.$t("setup.humanSkills.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_329, [
                  _createElementVNode("div", _hoisted_330, _toDisplayString(_ctx.$t("setup.humanSkills.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.humanSkills.Full_Details'))
                  }, null, 8, _hoisted_331)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_332, [
              _createElementVNode("div", _hoisted_333, [
                _createElementVNode("div", _hoisted_334, [
                  _createElementVNode("div", _hoisted_335, _toDisplayString(_ctx.$t("setup.humanSkills.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_336, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.humanSkills.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[140] || (_cache[140] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_337, [
                  _createElementVNode("div", _hoisted_338, _toDisplayString(_ctx.$t("setup.humanSkills.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_339, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.humanSkills.This_test_is_relevant_for_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[141] || (_cache[141] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_340, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.acceptableBehaviorsDetailModalStatus,
        "onUpdate:modelValue": _cache[75] || (_cache[75] = ($event: any) => ((_ctx.acceptableBehaviorsDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_341, [
            _createElementVNode("div", _hoisted_342, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_343, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[3].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[142] || (_cache[142] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[3].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_344, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Setup_your_Acceptable_Behaviors")), 1)
              ]),
              _createElementVNode("div", _hoisted_345, [
                _createElementVNode("div", _hoisted_346, [
                  _createElementVNode("div", _hoisted_347, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Description")), 1),
                  _createElementVNode("div", _hoisted_348, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_349, [
                  _createElementVNode("div", _hoisted_350, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.acceptableBehaviors.Full_Details'))
                  }, null, 8, _hoisted_351)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_352, [
              _createElementVNode("div", _hoisted_353, [
                _createElementVNode("div", _hoisted_354, [
                  _createElementVNode("div", _hoisted_355, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_356, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.acceptableBehaviors.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[143] || (_cache[143] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_357, [
                  _createElementVNode("div", _hoisted_358, _toDisplayString(_ctx.$t("setup.acceptableBehaviors.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_359, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t(
                        'setup.acceptableBehaviors.This_test_is_relevant_for_Text'
                      )
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[144] || (_cache[144] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_360, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.workStyleIdentifierDetailModalStatus,
        "onUpdate:modelValue": _cache[76] || (_cache[76] = ($event: any) => ((_ctx.workStyleIdentifierDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_361, [
            _createElementVNode("div", _hoisted_362, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_363, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[4].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[145] || (_cache[145] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[4].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_364, _toDisplayString(_ctx.$t("main.Work_Style_Identifier")), 1)
              ]),
              _createElementVNode("div", _hoisted_365, [
                _createElementVNode("div", _hoisted_366, [
                  _createElementVNode("div", _hoisted_367, _toDisplayString(_ctx.$t("setup.workStyleIdentifier.Description")), 1),
                  _createElementVNode("div", _hoisted_368, _toDisplayString(_ctx.$t("setup.workStyleIdentifier.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_369, [
                  _createElementVNode("div", _hoisted_370, _toDisplayString(_ctx.$t("setup.workStyleIdentifier.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.workStyleIdentifier.Full_Details'))
                  }, null, 8, _hoisted_371)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_372, [
              _createElementVNode("div", _hoisted_373, [
                _createElementVNode("div", _hoisted_374, [
                  _createElementVNode("div", _hoisted_375, _toDisplayString(_ctx.$t("setup.workStyleIdentifier.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_376, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.workStyleIdentifier.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[146] || (_cache[146] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_377, [
                  _createElementVNode("div", _hoisted_378, _toDisplayString(_ctx.$t("setup.workStyleIdentifier.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_379, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t(
                        'setup.workStyleIdentifier.This_test_is_relevant_for_Text'
                      )
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[147] || (_cache[147] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_380, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.logicTestDetailModalStatus,
        "onUpdate:modelValue": _cache[77] || (_cache[77] = ($event: any) => ((_ctx.logicTestDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_381, [
            _createElementVNode("div", _hoisted_382, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_383, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[5].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[148] || (_cache[148] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[5].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_384, _toDisplayString(_ctx.$t("create.Logic_Test")), 1)
              ]),
              _createElementVNode("div", _hoisted_385, [
                _createElementVNode("div", _hoisted_386, [
                  _createElementVNode("div", _hoisted_387, _toDisplayString(_ctx.$t("setup.logicTest.Description")), 1),
                  _createElementVNode("div", _hoisted_388, _toDisplayString(_ctx.$t("setup.logicTest.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_389, [
                  _createElementVNode("div", _hoisted_390, _toDisplayString(_ctx.$t("setup.logicTest.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.logicTest.Full_Details'))
                  }, null, 8, _hoisted_391)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_392, [
              _createElementVNode("div", _hoisted_393, [
                _createElementVNode("div", _hoisted_394, [
                  _createElementVNode("div", _hoisted_395, _toDisplayString(_ctx.$t("setup.logicTest.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_396, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.logicTest.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[149] || (_cache[149] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_397, [
                      _createElementVNode("div", _hoisted_398, _toDisplayString(_ctx.$t("setup.logicTest.This_test_is_relevant_for")), 1),
                      _createElementVNode("div", _hoisted_399, [
                        _createElementVNode("ul", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.logicTest.This_test_is_relevant_for_Text')
                    ), (value) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: 'This_test_is_relevant_for_Text_' + value
                            }, [
                              _cache[150] || (_cache[150] = _createElementVNode("svg", {
                                width: "8",
                                height: "9",
                                viewBox: "0 0 8 9",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("path", {
                                  d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                                  fill: "#1B1C1E"
                                })
                              ], -1)),
                              _createElementVNode("span", null, _toDisplayString(value), 1)
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ]),
    _createElementVNode("div", _hoisted_400, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.customSurveyDetailModalStatus,
        "onUpdate:modelValue": _cache[78] || (_cache[78] = ($event: any) => ((_ctx.customSurveyDetailModalStatus) = $event)),
        onOpened: _ctx.onModalOpened,
        "custom-class": "modal",
        width: "100rem"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_401, [
            _createElementVNode("div", _hoisted_402, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_403, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[6].timeDefault) + " " + _toDisplayString(_ctx.$t("create.min")), 1),
                  _cache[151] || (_cache[151] = _createElementVNode("div", { class: "sep" }, [
                    _createElementVNode("svg", {
                      width: "3",
                      height: "3",
                      viewBox: "0 0 3 3",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("circle", {
                        cx: "1.5",
                        cy: "1.5",
                        r: "1.5",
                        fill: "#696C80"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.sectionListStepTwo[6].modelType), 1)
                ]),
                _createElementVNode("div", _hoisted_404, _toDisplayString(_ctx.$t("create.Custom_Survey")), 1)
              ]),
              _createElementVNode("div", _hoisted_405, [
                _createElementVNode("div", _hoisted_406, [
                  _createElementVNode("div", _hoisted_407, _toDisplayString(_ctx.$t("setup.customSurvey.Description")), 1),
                  _createElementVNode("div", _hoisted_408, _toDisplayString(_ctx.$t("setup.customSurvey.Brief_Description")), 1)
                ]),
                _createElementVNode("div", _hoisted_409, [
                  _createElementVNode("div", _hoisted_410, _toDisplayString(_ctx.$t("setup.customSurvey.Details")), 1),
                  _createElementVNode("div", {
                    class: "message",
                    innerHTML: _ctx.nl2br(_ctx.$t('setup.customSurvey.Full_Details'))
                  }, null, 8, _hoisted_411)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_412, [
              _createElementVNode("div", _hoisted_413, [
                _createElementVNode("div", _hoisted_414, [
                  _createElementVNode("div", _hoisted_415, _toDisplayString(_ctx.$t("setup.customSurvey.Covered_Skills")), 1),
                  _createElementVNode("div", _hoisted_416, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.customSurvey.Covered_Skills_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'Covered_Skills_Text_' + value
                        }, [
                          _cache[152] || (_cache[152] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_417, [
                  _createElementVNode("div", _hoisted_418, _toDisplayString(_ctx.$t("setup.customSurvey.This_test_is_relevant_for")), 1),
                  _createElementVNode("div", _hoisted_419, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.str2list(
                      _ctx.$t('setup.customSurvey.This_test_is_relevant_for_Text')
                    ), (value) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: 'This_test_is_relevant_for_Text_' + value
                        }, [
                          _cache[153] || (_cache[153] = _createElementVNode("svg", {
                            width: "8",
                            height: "9",
                            viewBox: "0 0 8 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z",
                              fill: "#1B1C1E"
                            })
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(value), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onOpened"])
    ])
  ], 64))
}